<template>
  <div>
      <router-view></router-view>
  </div>
</template>

<script>
export default {

}

</script>
<style lang="scss" scoped>
    div {
        padding: 20px;
    }
</style>